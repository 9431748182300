.body{
  background-color: #dbbb95;
}
.container {
  border: 1px solid black;
  border-radius: 4px;
  background: #7030A0;
  display: flex;
}

.note-checked {
  text-decoration: line-through;
}

.note-priorities {
  display: inline;
}

.note-priorities span {
  opacity: 0.2;
}

.note-priorities span:hover {
  cursor: default;
}

.note-priority-1 :nth-child(1),
.note-priority-2 :nth-child(-n+2),
.note-priority-3 :nth-child(-n+3){
  opacity: 1;
}

.note-priorities svg {
  opacity: 0.2;
  color: black;
}

.note-list {
  margin-right: auto;
  margin-left: auto;
}


/*CreateReactApp Code*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: #234232;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
